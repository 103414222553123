.DayPicker_weekHeader_li {
    height: 25px !important;
    color: var(--main-color);
    font-weight: 600;
    font-size: 1em;;
  }
  
  /* Configuration to override <strong> month style */
  strong{
    font-weight:normal;
  }
  
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: var(--main-color);
    border: 1px double var(--main-color);
    color: #fff;
}

.CalendarDay__selected_span {
    background: var(--main-color);
    border: 1px double var(--main-color);
    color: #fff;
    opacity: 0.8;
}

.DateRangePickerInput__withBorder {
    border-radius: 2px;
    border: none;
}

.DateInput_input {
    border: none;
    color: #212529 !important;
    font-size: 14px;
}

.DateInput_1 {
    width: 100px;
}

.close-icon-picker-date {
    left: 0px;
    margin-top: -12px;
    position: absolute;
    color: #6c757d;
}

.DateRangePickerInput_calendarIcon {
    margin: 0 5px 0 0px;
    padding-left: 0;
    padding-top: 6px;
}

.DateRangePickerInput__showClearDates {
    padding-right: 0px;
}