.user-personal-data .custom-input-item {
  margin-bottom: 0 !important;
}

.user-personal-data img.rounded-circle {
  width: 30px !important;
}

.user-personal-data-numbers {
  padding: 0 5%;
}

.user-personal-data h2 {
  margin-bottom: 0;
}

.visits-counter {
  margin-bottom: 20px;
}

.visits-counter p,
.user-wallet-transaction-list .user-wallet-transaction-date {
  color: #aaaaaa;
  font-size: 16px;
}

.visits-counter p:first-of-type {
  font-size: 36px;
  color: var(--primary);
  font-family: work-Sans, sans-serif;
}

.user-wallet-value {
  border-bottom: 1px solid #f6f7fb;
  padding: 0 15%;
}

.wallet-section .visits-counter {
  background-color: #F9F9F9;
  padding: 10px 15px;
  border-radius: 10px;
}

.gmail-icon-list {
  width: 20px;
  height: 20px;
}

.user-name {
  word-break: break-word;
}

/* transactions */
.user-wallet-transaction-list {
  list-style: none;
  padding: 0;
}

.user-wallet-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.user-wallet-container::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border-radius: 4px;
}

.user-wallet-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Check for the latest compatibility status */
.user-wallet-container {
  scrollbar-width: thin;
  scrollbar-color: #C1C1C1 #f1f1f1;
}

/* Define thin scrollbar for Edge and IE */
/* Note: Microsoft Edge (Chromium) and IE support the scrollbar-color property */
/* The ::-ms-scrollbar-thumb and ::-ms-scrollbar-track pseudo-elements are used for Edge and IE */
.user-wallet-container::-ms-scrollbar-thumb {
  background-color: #C1C1C1;
}

.user-wallet-container::-ms-scrollbar-track {
  background-color: #f1f1f1;
}

.user-wallet-transaction-list li {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.user-wallet-transaction-value {
  color: var(--primary);
  font-size: 17px;
  font-weight: bold;
}

.user-wallet-transaction-negative-value {
  color: var(--error-color);
  font-size: 17px;
  font-weight: bold;
}

.user-wallet-transaction-title {
  font-weight: bold;
  font-size: 20px;
}