.image-search-view-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.image-search-thumbnail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}

.image-search-view-thumbnail, .image-search-view-thumbnail-selected {
  width: 100px;
  height: 4vw;
  max-width: 10vw;
  /* height: 60px; */
  object-fit: contain;
  margin: 0 10px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.image-search-view-thumbnail:hover {
  opacity: 1;
}

.image-search-view-thumbnail-selected {
  opacity: 1;
  border: 3px solid rgb(30, 166, 236);
  border-radius: 15px;
  padding: 5px 0;
  background-color: rgba(30, 166, 236, 0.5);
}