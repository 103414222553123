.report-style-class {
  margin: 1% auto;
	width: 100%;
	height: 100vh;
}

main.campaign-form .report-style-class {
  height: 60vh;
}

.report-style-class iframe {
	border: none;
  width: 100vw;
}

.tab {
  overflow: hidden;
}

/* Style the buttons that are used to open the tab content */
.tab {
  display: flex;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  border-bottom: 3px solid transparent;
}

.tablinks {
  padding: 5px 10px;
}

/* Change background color of buttons on hover */
.tablinks:hover {
  background-color: rgba(246, 246, 246, 0.6);
}

.tab-selected {
  font-weight: bold;
  border-bottom: 3px solid var(--main-secondary-color);
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border-top: none;
}

main#campaign-report .tablinks {
  font-size: 1.6em;
}