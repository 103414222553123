.combos-table-container {
    display: flex;
    justify-content: space-between;
}

.d-flex {
    display: flex;
}

.pl-10 {
    padding-left: 10px;
}

.pt-3 {
    padding-top: 3px;
}

.total-legend {
    font-size: 12px;
    color: #777777;
}

.color-primary {
    color: #1ea6ec;
}

.generic-color {
    color: #212529;
}

h3 {
    font-weight: 100;
}

h2 {
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 15px;
}

.label-filter {
    color: #212529;
    font-size: 14px;
}

.fields-filter {
    /* display: flex; */
    /* align-items: flex-end; */
    /* flex-wrap: wrap; */
    /* width: 100%; */
    padding-right: 15px;
}

.field-filter-item {
    padding: 10px;
}

.table-item {
    padding: 20px 2vw;
    width: 100%;
}

.btn-pill {
    height: 45px;
}

.arrow-right {
    width: 0px;
    height: 0px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #1ea6ec;
    margin: 0 0 15px 0;
}

.table-center {
    align-self: center;
}

@media (max-width: 1200px) {
    .combos-table-container {
        display: block;
    }

    .arrow-right {
        display: none;
    }
  }

.centralize {
    margin: 0 auto;
}

.center-wbk {
    text-align: -webkit-center;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.pdv-detail-photo {
    max-height: 60vh;
    margin: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    box-shadow: 1px 5px 24px 0 rgb(68 102 242 / 5%);
}

.empty-list-txt {
    margin-top: 10%;
    font-weight: lighter;
}

.loader-box {
    place-content: center;
}

/* tooltip  */
.tooltip-pdv {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip-pdv .tooltip-content {
    visibility: hidden;
    width: 120px;
    background-color: red;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 110%;
  }
  
  .tooltip-pvd-parent::after{
      display: none;
  }
  
  .hasTooltip:hover .tooltip-pvd-parent::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
  }

  .tooltip-pvd-parent {
    z-index: 1;
    display: none;
    text-decoration: none;
    padding: 3px;
    width: 250px;
    background: white;
    max-height: 50vh;
    overflow: auto;
}

.hasTooltip:hover .tooltip-pvd-parent, .tooltip-pvd-parent:hover {
    display: block;
    position: absolute;
    border: 1px solid #eff0f1;
    margin-top: -25px;
    margin-left: 20px;
}

.tooltip-pdv-item {
    padding: 10px 5px;
}

.tooltip-pdv-item h3 {
    font-size: 1em;
    font-weight: bold;
    word-break: break-word;
    white-space: break-spaces;
    margin-bottom: 0;

}

.tooltip-pdv-item span {
    word-break: break-word;
    white-space: break-spaces;
}

.tooltip-pdv-item p, .tag-pdv {
    background-color: rgba(30, 167, 236, 0.8);
    color: white;
    width: fit-content;
    padding: 1px 10px;
    font-size: 0.8em;
    margin: 0;
    border-radius: 10px;
    font-weight: bold;
    word-break: break-word;
    white-space: break-spaces;
}

.tag-pdv {
    background-color: rgba(30, 167, 236, 0.8);
    color: white;
    width: fit-content;
    padding: 3px 10px;
    font-size: 0.8em;
    height: 1.8em;
    margin: 0;
    border-radius: 10px;
    font-weight: bold;
    word-break: break-word;
    white-space: break-spaces;
}

@media (max-width: 500px){
    .tooltip-pvd-parent {
        left: 0;
    }
}

/* Maps */
.maps-type {
    background-color: rgba(30, 167, 236, 0.8);
    color: white;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 10px;
    font-size: 0.8em;
    margin: 0;
    border-radius: 10px;
    font-weight: bold;
}

.maps-title {
    color: rgba(30, 167, 236, 0.8);
    padding: 2px 0px;
    font-size: 0.9em;
    font-weight: bold;
}

.ReactTable .rt-thead .rt-resizable-header-content {
    text-align: center;
}
