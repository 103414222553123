p, vh2 {
    margin: 0;
    padding: 0;
}

.avatar-image {
    height: 35px;
    width: 35px;
}

.row-title {
    font-weight: 600;
}

.error-color {
    color: #ff4a36;
}

.success-color {
    color: #01edca;
}

.third-color {
    color: var(--third-color);
}

.primary-color {
    color: var(--primary);
}

.yellow-color {
    color: var(--alert-color);
}

.contact-icon {
    margin-right: 10px;
    color: #01edca;
}

.title-title {
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0;
}

.media-body {
    align-content: center;
    text-align: -webkit-center;
}

.card .card-body {
    padding: 10px;
}

.card-centered {
    height: 80%;
    margin: 5px auto;
}

.no-left {
    /* margin-left: 0; */
    /* padding-left: 0; */
}

.no-right {
    /* margin-right: 0; */
    /* padding-right: 0; */
}

.image-gallery-gondola {
    max-width: 180px;
    min-width: 100px;
}

.visit-details-evaluation {
    text-align: center;
    width: 100%;
}

.visit-details-evaluation tr td {
    border-bottom: 2px solid var(--main-secondary-color);
}

.visit-details-evaluation-table-thead tr td {
    border-bottom: 2px solid var(--main-secondary-color);
}

.visit-details-evaluation thead {
    font-weight: normal;
    font-style: italic;
    font-size: 0.85em;
}

.visit-details-evaluation td {
    font-size: 0.95em;
}

.td-brand-name {
    min-width: 150px;
}

.image-rate {
    z-index: 5;
    cursor: pointer;
    padding: 2px 10px;
    position: absolute;
    text-align: center;
    margin-left: 140px;
    color: white;
    height: 35px;
    width: 35px;
    border-radius: 100%;
    margin-top: -37px;
    opacity: 0.9;
}

.image-rate svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.image-count {
    z-index: 5;
    padding: 2px 10px;
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    opacity: 0.9;
    text-align: center;
    color: white;
}

.gondolas-images {
    margin: 15px 5px;
    overflow: hidden;
}


/* Visit modal */
.success-thumb-btn {
    border: 2px solid var(--main-secondary-color);
}

.error-thumb-btn {
    border: 2px solid #ff4a36;
}

.success-thumb-btn,
.error-thumb-btn {
    border-radius: 5px;
    padding: 10px;
    margin: 0 10px;
    width: 150px;
    text-align: center;
    /* cursor: pointer; */
}

.success-thumb-btn:hover,
.error-thumb-btn:hover {
    background-color: #F9F9F9;
}

.empty-list-img {
    width: 20%;
    padding-top: 10px;
    padding-bottom: 30px;
}

/* Table */
.table-images td {
    overflow: auto;
}

.table-images div {
    resize: both;
    overflow: auto;
    width: 120px;
    height: 120px;
    margin: 0px;
    padding: 0px;
    display: block;
}

.table-images td div {
    border: 0;
    width: auto;
    height: auto;
    min-height: 20px;
    min-width: 20px;
}

.survey-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    margin-bottom: 1em;
    justify-items: center;
    width: 90%;
    margin: 0 auto;
}

.survey-container-item:nth-child(2) {
    grid-column: 2 / 4;
    grid-row: span 2;
}

.survey-container-item {
    min-width: 32vw;
    display: grid;
    border-radius: 0.2rem;
    overflow: hidden;
    cursor: pointer;
}

.survey-container-item>div {
    grid-area: 1/1;
    height: fit-content;
}

@media (max-width: 800px) {
    .survey-container {
        grid-template-columns: repeat(1, 1fr);
    }
}