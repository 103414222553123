/* Form */
.grid-col2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
}

.combo-panel-select {
    width: 100%;
    display: flex;
    justify-content: space-between
}

.combo-panel-select:first-child {
    word-break: break-all;
}

/* Inputs */
.field-filter-item input, .custom-input input, select {
    background-color: #F9F9F9;
    border: 1px solid #F9F9F9;
    border-radius: 5px;
    height: 35px;
    padding-left: 5px;
}

.field-error input, .field-error textarea, .field-error select {
    border: 2px solid var(--error-color);
}

textarea{
    background-color: #F9F9F9;
    border: 1px solid #F9F9F9;
    border-radius: 5px;
    padding-left: 5px;
    min-width: 250px;
}

input:active, input:focus {
    border-color: #707070;
}

.campaign-form .custom-input {
    width: min-content;
}

.custom-input label {
    padding-bottom: 2px;
}

.multiselect-form div{
    /* min-width: 250px;
    max-width: 50vw; */
}

/* Card */
.form-card {
    margin: 0 12% 5% 12%;
}

.form-card .card-body {
    margin: 0 auto;
}

/* Multi select */
.dropdown-heading:active {
    border: 1px solid black;
}

/* Checkbox */
.checkmark-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .checkmark-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .checkmark-container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkmark-container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkmark-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkmark-container .checkmark:after {
    left: 9px;
    top: 6px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

@media (max-width: 600px) {
    .grid-col2 {
        grid-template-columns: 1fr;
    }
}