/* Main colors */
body {
    --main-color: #203ada;
    --main-secondary-color: #dbff00;
    --waiting-color: #ffb10a;
    --alert-color: #ffc30f;
    --error-color: #ff4a36;
    --gray-color: #6c757d;
    --success-color: #5cb85c;
    --third-color: #01edca;
}

h3.section-title {
    font-size: 1.4em;
    font-weight: 400;
    color: var(--main-color);
    text-align: center;
}

.clickable-p {
    color: var(--main-color);   
    text-decoration: underline;
    text-decoration-color: var(--main-color);
}

.google-icon {
    width: 20px;
    height: 20px;
}

.payment-icon {
    width: 40px;
    height: 30px;
}

.underline {
    color: var(--main-color);
    text-decoration: underline;
}

/* Commons  */
.pointer {
    cursor: pointer;
}

.pos-relative {
    position: relative;
}

.align-center {
    text-align: center;
}

.pl-10 {
    padding-left: 10px;
}

.pl-20 {
    padding-left: 20px;
}

.pr-8 {
    padding-right: 8px;
}

.pr-10 {
    padding-right: 10px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-20 {
    padding-top: 20px;
}

.pl-30 {
    padding-left: 30px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-5 {
    padding-bottom: 5px;
}

.pb-15 {
    padding-bottom: 15px;
}

.mr-3 {
    margin-right: 3px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}
.mb-20 {
    margin-bottom: 20px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.w-50vw {
    min-width: 50vw;
}

.w-35vw {
    min-width: 35vw;
}

.w-20vw {
    min-width: 20vw;
}

.w-19vw {
    min-width: 19vw;
}

.m-auto {
    margin: auto;
}

.ml-50 {
    margin-left: 50px !important;
}

.ml-5 {
    margin-left: 5px;
}

.ml-5p {
    margin-left: 5%;
}

.error-field {
    color: var(--error-color);
    font-size: 0.8em;
}

.scroll-container {
    overflow-y: scroll;
}

.main-color-background {
    background-color: var(--main-color);
}

.gray-color {
    color: var(--gray-color);
}

.main-color {
    color: var(--main-color);
}

.secondary-color {
    color: var(--main-secondary-color);
}

.error-color {
    color: var(--error-color);
}

.error-color-background {
    background-color: var(--error-color);
}

.none-color-background {
    background-color: #eeeeee;
}

.waiting-color-background {
    background-color: var(--waiting-color);
}

.waiting-color {
    color: var(--waiting-color);
    padding: 5px;
}

.saudation{
    color: var(--main-color);
    margin-top: 10px;
    margin-left: 10px;
}

.user-icon {
    width: 25px;
    height: 25px;
    color: var(--main-color) !important;
}

.btn-pill{
    border-radius: 50px;
    box-shadow: 0 5px 10px 2px rgba(88,103,221,.19);
    font-size: 1.1em;
    margin: 10px;
}

.waiting-text {
    font-size: 0.7em;
}

.btn-icon{
    float: right;
    display: flex;
}

.media{
    align-items: center;
}

.alg-center {
    align-items: center;
}

.alg-end {
    align-items: flex-end;
}

.modal-footer{
    justify-content: space-between;
}

.modal-footer-around{
    justify-content: space-around;
}

.modal-footer button{
    font-size: 14px;
}

.btn-outline-secondary:focus, .btn-outline-secondary:active{
    box-shadow: none;
}

.title-btn{
    display: flex;
     justify-content: space-between;
     flex-wrap: wrap;
}

.title-btn h5{
    margin-bottom: 15px;
}

.w-fit-content {
    width: fit-content;
}

/* Loading */
.loader-wrapper {
    height: 100%;
    width: 100%;
    display: block;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    background-color: #fff;
    z-index: 1001;
    top: 0
}

.loader-wrapper .loader {
    position: absolute;
    top: 50%;
    left: 0;
    text-align: center;
    right: 0;
    margin: 0 auto
}

.loader-wrapper .loader h4 {
    margin-top: 50px;
    margin-bottom: 0
}

.loader-wrapper .loader h4 span {
    font-size: 34px;
    color: #FFC107
}

.loader-wrapper.loderhide {
    display: none
}

body.dark-only .loader-wrapper {
    background-color: #293240
}

body.dark-only .loader-wrapper .loader {
    background-color: #293240 !important
}

body.dark-body-only .loader-wrapper {
    background-color: #293240
}

body.dark-body-only .loader-wrapper .loader {
    background-color: #293240 !important
}

.ReactTable .rt-thead .rt-tr {
    text-align: left;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
    overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child .rt-resizer {
    display: none;
}

.table-header-title p, .table-header-title {
    font-weight: bold;
    text-align: left;
    align-self: center;
    text-overflow: ellipsis;
    overflow: hidden;
}

.table-header-2lines {
    /* display: table-cell; */
    vertical-align: middle;
    height: 35px;
}

.ReactTable .rt-table .rt-thead .rt-tr .rt-th {
    align-self: center;
}

.ReactTable .rt-table {
    /* overflow: initial; */
}

.action-header {
    display: inline-block;
    position: absolute;
    margin-top: -80px;
    right: 20px;
}

.action-header {
    place-items: center;
}

/* table tooltip */
.ReactTable .rt-thead .rt-resizable-header-content {
    overflow: initial !important;
    text-overflow: ellipsis;
}
  
  .table-tooltip .table-tooltip-text {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    font-weight: 500;
    position: absolute;
    z-index: 1;
    top: 85%;
    margin-left: -9px;
    text-align: left;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .table-tooltip .table-tooltip-text::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 15px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
  }
  
  .table-tooltip:hover .table-tooltip-text {
    visibility: visible;
    opacity: 1;
  }

/* Forms */
.custom-item label {
    margin-bottom: .5rem;
}

.custom-input-item {
    margin-bottom: 15px;
}

.custom-item input, .custom-input-item {
    background-color: #F9F9F9;
    /* border: 1px solid #F9F9F9; */
    border-radius: 5px;
    height: 35px;
    padding-left: 5px;
    outline: none;
    border: 0;
}

/* SURVEYS */
.add-item {
    color: var(--main-color);
    cursor: pointer;
    margin: auto 20px;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.flex-column {
    flex-direction: column;
}

.flex-no-wrap {
    display: flex;
    align-items: center;
}

.space-between {
    justify-content: space-between;
    width: 100%;
}

.space-around {
    justify-content: space-around;
    width: 100%;
}

.place-center {
    place-content: center;
}

.clean-date {
    margin: 5px;
    cursor: pointer;
    color: #82888a;
}

.remove-item {
    color: #ff4a36;
    margin: auto 0;
    padding-left: 10px;
    cursor: pointer;
}

.remove-item-table {
    color: #6c757d;
    margin: auto 0;
    margin-bottom: 0;
    cursor: pointer;
    height: 20px;
}

.sort-icon {
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin: auto;
    margin-top: 150%;
}

.color-gray {
    color: #6c757d;
}

/* Visits */
.visit-0 {
    background-color: var(--main-secondary-color);
    color: var(--dark);
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 10px;
    font-size: 0.8em;
    margin: 0 auto;
    border-radius: 10px;
    font-weight: bold;
}

.visit-1 {
    background-color: var(--waiting-color);
    color: white;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 10px;
    font-size: 0.8em;
    margin: 0 auto;
    border-radius: 10px;
    font-weight: bold;
}

.visit-2 {
    background-color: var(--main-color);
    color: white;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 10px;
    font-size: 0.8em;
    margin: 0 auto;
    border-radius: 10px;
    font-weight: bold;
}

.visit-canceled {
    background-color: var(--error-color);
    color: white;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 10px;
    font-size: 0.8em;
    margin: 0 auto;
    border-radius: 10px;
    font-weight: bold;
}

/* PDVs */
.authorization-image {
    width: 25px;
    height: 25px;
}

.authorization-image-opacity {
    opacity: 0.4;
    filter: alpha(opacity=40);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
}

.confirm-button {
    display: flex;
    text-align: center;
    width: 100%;
    padding: 10px 20px;
    color: var(--main-color);
    border-radius: 5px;
    margin: 0 10px;
    width: 150px;
    text-align: center;
    border: 2px solid var(--main-color);
}

.confirm-button:hover {
    background-color: #F9F9F9;
}

table.working-hours-table tr td:nth-child(1) {
    width: 150px;
}

table.working-hours-table tr td:nth-child(2) {
    width: 130px;
}

.working-hours-week-day-container {

}

.working-hours-week-day-container span {
    margin: 5px;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    border: 2px solid var(--main-color);
    padding: 5px 10px;
}

.working-hours-week-day-container span.working-hours-week-day-selected {
    background-color: var(--main-color);
    color: white;
}

.working-hours-week-day-container span.working-hours-week-day-unselected {
    color: var(--main-color);
}

.working-hours-week-day-container span.working-hours-week-day-unselected:hover {
    color: white;
    background-color: rgba(32, 58, 218, .70);
    border: 2px solid rgba(32, 58, 218, .70);
    font-weight: bold;
}

/* Table filters */
.filter-container-button {
    margin-top: 15px;
}

.filter-container-button button {
    padding: 0 15px;
}


.tag-primary {
    background-color: var(--main-color);
    color: white;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 8px;
    font-size: 0.8em;
    margin-right: 6px;
    border-radius: 10px;
    font-weight: bold;
    margin-bottom: 8px;
    height: fit-content;
}

.success-tag {
    background-color: var(--success-color);
    padding: 4px 8px;
    font-size: 0.8em;
    color: white;
    border-radius: 10px;
    width: -moz-fit-content;
}

.late-tag {
    background-color: var(--error-color);
    padding: 4px 8px;
    font-size: 0.8em;
    color: white;
    border-radius: 10px;
    width: -moz-fit-content;
}

.main-color-tag {
    background-color: var(--main-color);
    padding: 4px 8px;
    font-size: 0.8em;
    color: white;
    border-radius: 10px;
    width: -moz-fit-content;
}

.creationStatus {
    background-color: rgb(255, 166, 0);
    width: fit-content;
    padding: 0 5px;
    border-radius: 10px;
}

.planStatus {
    background-color: #8aafff;
    width: fit-content;
    padding: 0 5px;
    border-radius: 10px;
}

.executionStatus {
    background-color: #01edca;
    width: fit-content;
    padding: 0 5px;
    border-radius: 10px;
}

.campaigns_list_recognition_products {
    background-color: rgba(219, 255, 0, 0.5);
    width: fit-content;
    padding: 0 4px;
    border-radius: 10px;
}

.campaigns_list_recognition_prices {
    background-color: rgba(236, 66, 245, 0.5);
    width: fit-content;
    padding: 0 4px;
    border-radius: 10px;
}

button.gm-ui-hover-effect {
    outline-style: none;
}

/* Don't show table loading shadow while filtering data */
.ReactTable .-loading.-active{
    opacity: 0 !important;
    z-index: 0 !important;
    pointer-events: none !important;
}

.import-file-button .dzu-dropzone:hover {
    background-color: rgba(68, 102, 242, 0.2) !important;
    width: 20vw; 
}

.sidebar-item-dot {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: hsla(0,0%,100%,.7);
    margin-right: 8px;
    margin-bottom: 2px;
}

/* Sidebar arrow */
div.sidebar-header-itemgroup {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.arrowbtn:after {
    display: inline-block;
    content: "";
    width: 9px;
    height: 9px;
    top: 50%;
    left: 50%;
}
  
.arrowbtn.arrowbtn-up {
    top: 20px;
}

.arrowbtn.arrowbtn-up:after {
    margin-left: -4.5px;
    margin-top: -2.25px;
    border-top: 2px solid;
    border-left: 2px solid;
    transform: rotate(135deg);
}

li.active .arrowbtn.arrowbtn-up:after {
    transform: rotate(225deg);
}

span.sidebar-header_menu-item.menu-item_title-detail {
    font-size: 11px;
    font-weight: 300;
}

.no-selectable {
    -webkit-touch-callout: none;  /* iPhone OS, Safari */
    -webkit-user-select: none;    /* Chrome, Safari 3 */
    -khtml-user-select: none;     /* Safari 2 */
    -moz-user-select: none;       /* Firefox */
    -ms-user-select: none;        /* IE10+ */
    user-select: none;            /* Possível implementação no futuro */
}


/* table PDF export option */
.selection-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    background: #f7f7f7;
    border-bottom: 1px solid #ccc;
    transition: transform 0.3s;
    transform: translateY(-50px);
    opacity: 0;
    pointer-events: none;
}

.selection-bar.active {
    transform: translateY(0);
    opacity: 1;
    pointer-events: auto;
}

.selected-count {
    font-weight: bold;
}

input.custom-checkbox {
    -webkit-appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid hsl(0, 0%, 85%);
    border-radius: 1px;
    vertical-align: sub;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    outline: none;
    left: 5px;
}

input.custom-checkbox:checked {
    background-color: rgb(30, 166, 236);
    border-color: rgb(30, 166, 236);
}

input.custom-checkbox:checked+label {
    text-decoration: line-through;
    color: hsl(0, 0%, 70%);
    font-weight: 600;
    background-color: hsl(0, 0%, 97%);
}

input.custom-checkbox:checked:focus,
input.custom-checkbox:checked:hover {
    box-shadow: 0 0 0 3px hsl(0, 0%, 85%);
    border-color: rgb(30, 166, 236);
}

input.custom-checkbox:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==");
    background-size: 40px;
    background-repeat: no-repeat;
    background-position: center;
}

input.custom-checkbox:focus,
input.custom-checkbox:hover {
    box-shadow: 0 0 0 3px hsl(0, 0%, 92%);
    border-color: hsl(0, 0%, 55%);
}

label.custom-checkbox {
    padding: 0.75rem 1rem 0.75rem calc(1.2rem * 2.25);
    display: inline-block;
    font-size: 17px;
    width: 100%;
    user-select: none;
    cursor: pointer;
}