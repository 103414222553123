.users-selection-modal-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 30px;
    min-height: 72vh;
}

.users-selection-modal-container-column {
    max-height: 70vh;
    overflow-y: scroll;
}

.users-selection-modal-container ul, ul.users-groups-form-userslist {
    list-style: none;
    padding: 0;
}

.users-selection-modal-container li,  .users-groups-form-userslist li {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.users-selection-modal-container li:hover {
    background-color: #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.users-selection-modal-container li:selected {
    background-color: #ffc107;
    border: 1px solid #e0a800;
}
